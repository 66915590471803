.dropdown-enter {
  max-height: 0px;
  background-color: #3b9cd5;
}

.dropdown-enter-active {
  max-height: 440px;
  background-color: #022347;
  transition: all 300ms;
}

.dropdown-exit {
  display: none;
}

.dropdown-exit-active {
  display: none;
}
