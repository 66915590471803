:root {
  scroll-behavior: smooth;
}

html,
body,
#root {
  height: 100dvh;
  overflow: hidden;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

* {
  scrollbar-width: thin;
  scrollbar-color: #019cd5 #c4e7f4;
}
.disableProfile {
  pointer-events: none;
}
@media print {
  * {
    display: none !important;
  }
}

@font-face {
  font-family: 'Credit Card';
  src: local('Credit Card'),
    url(./assets/fonts/creditcard/creditcard.ttf) format('truetype');
}

@font-face {
  font-family: 'pacifico';
  src: local('Pacifico'),
    url(./assets/fonts/pacifico/Pacifico-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: local('IBM Plex Sans'),
    url(./assets/fonts/ibmplexsans/ibmplexsans.ttf) format('truetype');
}

/* not in use */
/* @font-face {
  font-family: 'Lastman';
  src: local('Lastman'),
    url(./assets/fonts/lastman/lastman.ttf) format('truetype');
} */

/* not in use */
/* @font-face {
  font-family: 'Christmas Card';
  src: local('Christmas Card'),
    url(./assets/fonts/christmascard/christmascard.ttf) format('truetype');
} */

/* not in use */
/* @font-face {
  font-family: 'Subscriber';
  src: local('Subscriber'),
    url(./assets/fonts/subscriber/subscriber.otf) format('opentype');
} */

/* font added using typeface npm package -> typeface Montserrat */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap'); */

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #c4e7f4;
}

::-webkit-scrollbar-thumb {
  background: #019cd5;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
  width: 6.5px;
}

.touch-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.touch-scroll::-webkit-scrollbar {
  width: 10px;
}

.recurly-element,
.recurly-hosted-field {
  height: 3.125rem;
  border-radius: 0.25rem;
}

.participant-strip .slick-slider .slick:hover {
  background-color: rgba(211, 211, 211, 0.8);
}

.participant-strip .slick-slider .slick-slide > div {
  margin-left: 0.18rem;
}

.no-bar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.filter-dropdown::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.touch-scroll::-webkit-scrollbar {
  width: 0px;
}

.react-toast-notifications__container {
  z-index: 20000 !important;
}

.socials:hover > svg {
  color: white;
}

.slideshow::-webkit-scrollbar {
  background: none;
  width: 0px;
  height: 0px;
}

.buddyPopover {
  top: -9px !important;
}

button[aria-label='Close'] svg {
  height: 20px;
  width: 20px;
}

@media (max-width: 1279px) {
  .free-book-div {
    margin-top: -10px;
  }
}

@media (max-width: 500px) {
  .book-reader-arrow {
    left: 56px !important;
    bottom: calc(1rem / 2 * -1);
  }
  button[aria-label='Close'] svg {
    height: 17px;
    width: 17px;
  }
  .swipeToastTransitionUp,
  .swipeToastTransitionLeft,
  .swipeToastTransitionRight {
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    margin-top: -20% !important;
  }
  .swipeToastTransitionLeft {
    margin-left: 120% !important;
    margin-top: 0% !important;
  }
  .swipeToastTransitionRight {
    margin-top: 0% !important;
    margin-left: -120% !important;
  }
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  @media (max-width: 500px) {
    .bookmark-modal {
      margin-top: 40px !important;
    }
    .bookshlef-modal-content {
      top: 11rem !important;
    }
    .add-to-bookshelf {
      height: initial !important;
    }
    .close-bookmarks {
      margin-top: -15px !important;
    }
    /* .help-controls {
      bottom: calc(45px + env(safe-area-inset-top)) !important;
    } */
    .big-arrow {
      bottom: 180px !important;
    }
  }
}

.slick-slider .slick-prev,
.slick-slider .slick-next {
  background: #019cd5;
  width: 73px;
  height: 73px;
  color: #fff;
  border-radius: 50%;
}

.slick-slider .slick-next {
  right: -80px;
}

.slick-slider .slick-prev {
  left: -80px;
}

.slick-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.dot-spinner {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #3996da, 12.72984px -12.72984px 0 0 #3996da,
    18px 0 0 0 #3996da, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1.5s infinite linear;
}

@keyframes dotSpin {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 #3996da, 12.72984px -12.72984px 0 0 #3996da,
      18px 0 0 0 #3996da, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 0 #3996da, 18px 0 0 0 #3996da,
      12.72984px 12.72984px 0 0 #3996da, 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #3996da,
      12.72984px 12.72984px 0 0 #3996da, 0 18px 0 0 #3996da,
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #3996da,
      0 18px 0 0 #3996da, -12.72984px 12.72984px 0 0 #3996da,
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #3996da,
      -12.72984px 12.72984px 0 0 #3996da, -18px 0 0 0 #3996da,
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #3996da,
      -18px 0 0 0 #3996da, -12.72984px -12.72984px 0 0 #3996da;
  }
  75% {
    box-shadow: 0 -18px 0 0 #3996da,
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #3996da,
      -12.72984px -12.72984px 0 0 #3996da;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 #3996da, 12.72984px -12.72984px 0 0 #3996da,
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #3996da;
  }
}

.overlay-backdrop {
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  position: absolute;
  background-color: #083261;
  z-index: 10;
  backdrop-filter: saturate(180%) blur(3px);
}

.create-slot input[type='checkbox'] + label,
.delete-slot input[type='checkbox'] + label {
  display: flex;
  justify-content: space-between;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  align-items: center;
}

.delete-slot input[type='checkbox'] + label {
  display: flex;
  justify-content: flex-start;
}

.create-slot input[type='checkbox'],
.delete-slot input[type='checkbox'] {
  display: none;
}

.create-slot input[type='checkbox'] + label:after,
.delete-slot input[type='checkbox'] + label:before {
  content: '';
  border: 1.5px solid #019cd5;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  color: transparent;
  transition: 0.2s;
}

.create-slot input[type='checkbox'] + label:active:after,
.delete-slot input[type='checkbox'] + label:active:before {
  transform: scale(0);
}

.create-slot input[type='checkbox']:checked + label:after,
.delete-slot input[type='checkbox']:checked + label:before {
  background-color: #019cd5;
  border-color: #019cd5;
  color: #fff;
  content: '\2713';
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-slot input[type='checkbox']:checked + label:before {
  background-color: #fff;
  border-color: #019cd5;
  color: #000;
}

.timer-picker-selected {
  opacity: 0.9 !important;
}

.hide-scroll {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}
