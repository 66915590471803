/* reader wrapper */
.scale-enter {
  opacity: 0;
  transform: scale(0.9);
}

.scale-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.scale-exit {
  opacity: 1;
}

.scale-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/* reader-overlay */
.reader-overlay-enter {
  display: none;
  opacity: 0;
}

.reader-overlay-enter-active {
  opacity: 1;
  display: block;
  transition: all 0.3s ease-out;
}

.reader-overlay-exit {
  opacity: 1;
}

.reader-overlay-exit-active {
  display: none;
  opacity: 0;
  transition: all 0.3s ease-out;
}

/* reader-library */
.reader-library-enter {
  top: 100%;
}

.reader-library-enter-active {
  top: 20%;
  height: 80vh;
}

.reader-library-exit {
  top: 20%;
}

.reader-library-exit-active {
  top: 100%;
}

/* make button size small to avoid overlapping with flip button  */
.reader-button {
  width: 40px;
  height: 40px;
}

/* make button size larger to on screens with more height  */
@media screen and (min-height: 800px) {
  .reader-button {
    width: 50px;
    height: 50px;
  }
}
